import axios from "axios";
import {ISettings} from "@/interfaces/ISettings";

export class LpApiService {
    static domain = process.env.NODE_ENV === "production" ? "https://upload.liveportrait.com" : "http://0.0.0.0:8887";

    static async sendData(data: any) {
        return await axios.post(`${(LpApiService.domain)}/nicki_minaj_data_records`,
            {nicki_minaj_data_record: data},
            {
                headers: {
                    "Authorization": "What are you looking at?!",
                    "Content-type": "application/json",
                    "Accept": "application/json",
                }
            })
    }

    static async saveSettings(values: any) {
        await axios.post(`${(LpApiService.domain)}/nicki_minaj/settings`, values)
    }

    static async getSetting(): Promise<ISettings> {
        const response = await axios.get(`${(LpApiService.domain)}/nicki_minaj/settings`)
        response.data.nicki_minaj_website_enabled = response.data.nicki_minaj_website_enabled === 'true'
        return response.data as ISettings
    }
}
